var lang = {
    "datePicker": {
        cancel: 'Annuler',
        clear: 'Effacer',
        done: 'Valider',
        previousMonth: '‹',
        nextMonth: '›',
        months: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ],
        monthsShort: [
            'Jan',
            'Fév',
            'Mar',
            'Avr',
            'Mai',
            'Jun',
            'Jul',
            'Aoû',
            'Sep',
            'Oct',
            'Nov',
            'Déc'
        ],
        weekdays: [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi'
        ],
        weekdaysShort: [
            'Dim',
            'Lun',
            'Mar',
            'Mer',
            'Jeu',
            'Ven',
            'Sam'
        ],
        weekdaysAbbrev: [
            'D',
            'L',
            'M',
            'M',
            'J',
            'V',
            'S'
        ]
    }
};

exports.lang = lang;