import { addLocale, useLocale } from "ttag";
const dataFr = require("../i18n/fr.po.json")
const dataEn = require("../i18n/fr.po.json")


export default function initTTag(){
    const locale = document.documentElement.lang;
    addLocale("fr", dataFr);
    addLocale("en", dataEn);
    useLocale(locale);
}