import 'core-js/actual'
import "materialize-css/dist/js/materialize.js";
import toastr from "toastr";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/menu.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/ui/widgets/autocomplete";
import initTTag from "./init-ttag"
import * as JSZip from "jszip";
window.JSZip = JSZip; //need jszip for button export excel in datatable

import "datatables.net";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "easy-pie-chart/dist/jquery.easypiechart";
// noinspection ES6UnusedImports
import Dropzone from "dropzone";
import lang from "./lang"
import "../css/app.css";
import {autocomplete} from "../ts/autocomplete";

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

window.Chart = Chart

window.lang = lang.lang
window.toastr = toastr
window.Dropzone = Dropzone

initTTag()
autocomplete(".rts-ai-autocomplete")
window.initDropZone = function (post_url, selDropzone, selButton, selInject, selProgress, selAdd, selTpl, bindTplData) {
    var dropContainer = $(selInject);
    var $dropProgress = $(selProgress);
    var $dropAdd = $(selAdd);
    var template = $(selTpl).get(0).innerHTML;
    var fileAdded = 0;
    var filesended = 0;
    var show_progression = false;
    var _updateUi = function () {
        if (show_progression) {
            $dropProgress.removeClass("hide");
            $dropAdd.addClass("hide");
            $dropProgress.find(".percent").text(filesended + "/" + fileAdded);
        } else {
            $dropProgress.addClass("hide");
            $dropAdd.removeClass("hide");

        }
    };

    (new Dropzone(selDropzone,
        {
            clickable: selButton,
            url: post_url,
            paramName: "file", // The name that will be used to transfer the file
            maxFilesize: 5, // MB
            addRemoveLinks: false,
            previewsContainer: '.drop-inject',
            previewTemplate: ' <span></span>',
            success: function (file, server_response) {

                for (var i = 0, imax = server_response.files.length; i < imax; i++) {
                    var tpl = bindTplData($(template), server_response.files[i]);
                    dropContainer.append(tpl);
                }

            },
            addedfile: function () {
                fileAdded++;
                show_progression = true;
                _updateUi();
            },
            queuecomplete: function () {
                fileAdded = 0;
                filesended = 0;
                show_progression = false;
                _updateUi();
            },
            complete: function () {
                filesended++;
                _updateUi();
            },
            error: function (file, json) {
                toastr.error(json.message || "unknow error");
            }
        }));
}

$(function () {
    "use strict";

    // sidenav

    $(".sidenav").sidenav();
    $(".collapsible").collapsible();

    $('.sidebar-toggle').on("click", function () {
        $("#main-wrapper").toggleClass("show-sidebar");
    });

    var setsidebartype = function () {
        var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
        var $mainWrapper = $("#main-wrapper");
        var isMini = width < 993 || $mainWrapper.attr("data-force-mini-sidebar") == "true";

        if (isMini) {
            $mainWrapper
                .addClass("mini-sidebar")
                .attr("data-sidebartype", "mini-sidebar");
        } else {
            $mainWrapper
                .removeClass("mini-sidebar")
                .attr("data-sidebartype", "full");
        }
    };

    $(window).ready(setsidebartype);
    $(window).on("resize", setsidebartype);

    // active menu on loading

    $(".sidenav a[href='" + window.location.pathname + "']").each(function () {
        $(this).parent("li").addClass("active");
        $(this).parents(".collapsible-body").each(function () {
            $(this).css({"display": "block"});
            $(this).parent("li").addClass("active");
        });
    });

    // others

    $('.tooltipped').tooltip();

    $("body").trigger("resize");

    //checkbox with outline event manager
    $(".el-card-checkbox [type=\"checkbox\"]").change(function (ev) {
        var $ck = $(ev.target);
        var isChecked = $ck.is(":checked");
        var $card = $ck.parents(".card.result");
        var checkedClass = "el-checked";

        if (isChecked) {
            $card.addClass(checkedClass);
        } else {
            $card.removeClass(checkedClass);
        }
    });

    $("select").formSelect();
});