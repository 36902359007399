import $ from "jquery"

const POSITION_TOP = {my: "left bottom", at: "left top", collision: "none"}
const POSITION_DOWN = {my: "left top", at: "left bottom", collision: "none"}

interface IRequest {
    term: string
}

interface IConfig {
    submit: boolean
    minLen: number
    position: string
    src: string
    inputIdSelector: string
}

const ConfigDefault: IConfig = {
    minLen: 3,
    submit: true,
    position: "down",
    src: "",
    inputIdSelector: ".autocomplete-id"
}

export class Autcomplete {
    $doc: any
    config: IConfig

    private onSelectCallback: (label: string, value: string) => void = (x, y) => {
    }

    constructor(query_selector: string | any, config: any) {
        this.$doc = $(query_selector)
        this.config = {...ConfigDefault, ...config}

        this.startListener()
    }

    onSelected(callback: (label: string, value: string) => void): void {
        this.onSelectCallback = callback
    }

    private getPosition(position: string): any {
        return position == "top" ? POSITION_TOP : POSITION_DOWN;
    }

    private processEvent(event: Event, ui: any): string {
        event.preventDefault()
        const label = ui.item.label ? ui.item.label : ui.item.value
        $(event.target).val(label)

        return label
    }

    private processFocus(event: Event, ui: any): void {
        this.processEvent(event, ui)
    }

    private processSelect(event: Event, ui: any, $id: any, isSubmit: boolean): any {
        const label = this.processEvent(event, ui)

        $id.val(ui.item.value)
        this.onSelectCallback(label, ui.item.value)
        if (isSubmit) {
            $(event.target).parents("form").find("button[type=submit]").click();
        }
    }


    private startListener(): void {
        let self = this
        document.addEventListener("DOMContentLoaded", function () {
            self.$doc.each((index: any, doc: any) => {
                let $doc = $(doc)
                let data = <IConfig>$doc.data()
                let config = {...self.config, ...data}

                let $id = $doc.parent().find(config.inputIdSelector)

                $doc.autocomplete({
                    position: self.getPosition(config.position),
                    source: function (request: IRequest, response: (a: any) => void) {
                        $.getJSON(config.src, {
                            q: request.term
                        }, function (data) {

                            response(data);
                        });
                    },
                    minLength: config.minLen,
                    select: function (event, ui) {
                        console.log("select")
                        self.processSelect(event, ui, $id, config.submit)
                    },
                    focus: function (event, ui) {
                        self.processFocus(event, ui)
                    }
                })
            })
        })
    }
}

export function autocomplete(query: string): void {
    new Autcomplete(query, ConfigDefault)
}